<script lang="ts" setup>
import {
  MediaImageFragment,
  ParagraphEducationOverview,
  ParagraphEducationOverviewItem,
  ParagraphEducationOverviewTeaserItem,
} from '~/api/graphql/generated'
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide'

const props = defineProps<{
  entity: ParagraphEducationOverview
}>()

const title = computed(() => props.entity.fieldTitle || '')
const description = computed(() => props.entity.fieldIntroduction?.processed || '')
const items = computed(() => {
  return (
    props.entity.fieldEducationOverviewItem?.map((el) => {
      const item = el?.entity as ParagraphEducationOverviewItem
      return {
        image: item?.fieldImage?.entity as MediaImageFragment,
        title: item.fieldTitle || '',
        content: item.fieldText?.processed || '',
        link: {
          url: item.fieldLink?.url?.path || '',
          text: item.fieldLink?.title || '',
          target: item.fieldLink?.options.attributes.target || '_self',
        },
      }
    }) || []
  )
})

const teasers = computed(() => {
  return (
    props.entity.fieldEducationOverviewTeaser?.map((el) => {
      const item = el?.entity as ParagraphEducationOverviewTeaserItem
      return {
        image: item?.fieldImage?.entity as MediaImageFragment,
        title: item.fieldTitle || '',
        description: item.fieldText?.processed || '',
        link: {
          type: item.fieldCtaStyle || 'primary',
          url: item.fieldLink?.url?.path || '',
          text: item.fieldLink?.title || '',
          target: item.fieldLink?.options.attributes.target || '_self',
        },
      }
    }) || []
  )
})

const sliderOptions = {
  pagination: true,
  arrows: false,
  mediaQuery: 'min',
  perPage: 1,
  perMove: 1,
  gap: 12,
  breakpoints: {
    640: {
      perPage: 2,
    },
    768: {
      perPage: 3,
    },
  },
} as Options

const currentIndex = ref(0)

const boxMouseEnter = (index: number) => {
  currentIndex.value = index
}
</script>

<template>
  <Section v-animate="{ cluster: true }" large wide>
    <div class="section-cells container grid">
      <div v-animate="{ animation: 'vertical-reveal' }" class="background" />
      <div class="t-10 ds-5">
        <h2 v-animate="{ animation: 'vertical-reveal' }" class="title text-xlarge">{{ title }}</h2>
        <div v-animate="{ animation: 'vertical-reveal' }" class="description" v-html="description" />
      </div>
      <div class="cells grid desktop">
        <div class="t-7 boxes-outer">
          <ul class="cul boxes">
            <li
              v-for="(cell, index) in items"
              :key="index"
              v-animate="{ animation: 'vertical-reveal' }"
              @mouseenter="boxMouseEnter(index)"
            >
              <div class="original">
                <h3>{{ cell.title }}</h3>
                <div class="text-small" v-html="cell.content" />
                <NuxtLink :to="cell.link.url" class="link-default text-small">{{ cell.link.text }}</NuxtLink>
              </div>
              <div class="fake">
                <h3>{{ cell.title }}</h3>
                <div class="text-small" v-html="cell.content" />
                <NuxtLink :to="cell.link.url" class="link-default text-small">{{ cell.link.text }}</NuxtLink>
              </div>
            </li>
          </ul>
        </div>
        <div class="t-5">
          <ul v-animate="{ animation: 'vertical-reveal' }" class="cul images">
            <li v-for="(cell, index) in items" :key="index" :class="currentIndex === index ? 'active' : ''">
              <Image :entity="cell.image" type="teaser" />
            </li>
          </ul>
        </div>
      </div>
      <div v-animate="{ animation: 'vertical-reveal' }" class="cells mobile">
        <Splide :options="sliderOptions" :has-track="false">
          <SplideTrack class="splide-track">
            <SplideSlide v-for="(cell, index) in items" :key="index" class="splide-slide">
              <Image :entity="cell.image" type="teaser" />
              <div class="wrap">
                <h3>{{ cell.title }}</h3>
                <div class="text-small hyphenate" v-html="cell.content" />
                <NuxtLink :to="cell.link.url" class="link-default text-small">{{ cell.link.text }}</NuxtLink>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>
    </div>
    <div v-animate="{ animation: 'vertical-reveal', cluster: true }" class="section-teasers">
      <ul class="cul teasers">
        <li
          v-for="(teaser, index) in teasers"
          :key="index"
          v-animate="{ animation: 'vertical-reveal' }"
          :class="teaser.image ? 'has-image' : ''"
        >
          <div class="content">
            <h3>{{ teaser.title }}</h3>
            <div class="text-small" v-html="teaser.description" />
            <Button v-bind="{ [teaser.link.type]: true }" :to="teaser.link.url" :target="teaser.link.target">
              {{ teaser.link.text }}
            </Button>
          </div>
          <div v-if="teaser.image" class="image">
            <Image :entity="teaser.image" type="sidebar-logo" />
          </div>
        </li>
      </ul>
    </div>
  </Section>
</template>
<style scoped lang="scss">
.section-education-overview {
  overflow: hidden;
}
.section-cells {
  position: relative;

  @include fluid(padding-top, 32px, 128px);
  @include fluid(padding-bottom, 64px, 128px);
  .background {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    background-color: var(--c-green-dark-100);
    --ml: calc(50% - 50vw);
    margin-left: var(--ml);
    z-index: -1;
    @include breakpoint(tl) {
      width: 100vw;
      --ml: calc(50% - min(50vw, calc(var(--layout-max-width) / 2)));
      max-width: calc(100% + (-1 * var(--ml)));
    }
  }
  .title,
  .description {
    color: var(--c-white);
  }
  .cells {
    color: var(--c-white);
    :deep(*) {
      color: var(--c-white);
    }
    :deep(.link-default) {
      --c-secondary: var(--c-green-dark-40);
    }
    &.desktop {
      display: none;
      @include breakpoint(tl) {
        display: grid;
      }
    }
    &.mobile {
      @include breakpoint(tl) {
        display: none;
      }
    }
  }
  .splide__track {
    overflow: visible;
  }
  .splide__slide {
    display: grid;
    grid-template-rows: auto 1fr;
  }
  :deep(.splide__pagination__page.is-active) {
    background-color: var(--c-white);
  }
  .wrap {
    padding: 24px 30px;
    background: var(--c-green-dark-90);
    min-width: 0;
  }
  .boxes-outer {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  }
  .boxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
    background: var(--c-green-dark-90);
    li {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr;
      background: var(--c-green-dark-100);
      .original,
      .fake {
        display: grid;
        place-content: center;
        padding: 32px 40px;
        grid-column: 1;
        grid-row: 1;
      }
      .original {
        opacity: 0;
        pointer-events: none;
        z-index: -1;
      }
      .fake {
        a {
          max-height: 0;
          @include transitions(max-height);
          overflow: hidden;
          justify-self: start;
        }
      }
      &:hover {
        background: var(--c-green-dark-90);
        box-shadow: 0px 20px 50px 0px rgba(5, 54, 55, 0.25);
        .fake a {
          max-height: 30px;
        }
      }
    }
  }
  .images {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    --column-width: calc((100% - 4 * var(--gap)) / 5);
    width: calc(100% + calc(min(50vw, calc(var(--layout-max-width) / 2)) - (6 * var(--column-width) + 5 * var(--gap))));
    min-height: 100%;
    li {
      grid-column: 1;
      grid-row: 1;
      @include transitions(opacity);
      opacity: 0;
      &.active {
        opacity: 1;
      }
      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
      }
    }
  }
}

.section-teasers {
  display: grid;
  grid-template-columns: 1fr min(100% - 2 * var(--layout-padding), var(--container-max-width)) 1fr;

  &::before,
  &::after {
    content: '';
    background: var(--c-background-light);
  }

  &::after {
    @include breakpoint(tl) {
      background-color: transparent;
    }
  }
}
.teasers {
  display: grid;
  grid-template-columns: 100%;
  @include breakpoint(tl) {
    grid-template-columns: 1fr 1fr;
  }
  background: var(--c-background-light);
  li {
    padding: 30px 0;
    @include breakpoint(tl) {
      padding: 40px 80px 50px;
    }
    &:first-child {
      padding-left: 0;
      border-bottom: 1px solid var(--c-line-light);
      @include breakpoint(tl) {
        border-bottom: none;
        border-right: 1px solid var(--c-line-light);
      }
    }
    &.has-image {
      display: grid;
      grid-template-columns: 100%;
      @include breakpoint(t) {
        grid-template-columns: 1fr auto;
        gap: 24px;
        align-items: center;
      }
      .image {
        grid-row: 1;
        @include breakpoint(t) {
          grid-row: auto;
        }
        text-align: right;
      }
      img {
        @include fluid(width, 80px, 150px);
      }
    }
    p {
      color: var(--c-text-medium);
    }
  }
}
</style>
